// src/mixins/commonMethods.js
import '@/css/news/home.scss'
import FootModuleLoader from '@/pages/components/FootModuleLoader.vue'
const navModule_1 = () => import('@/pages/modules/navModule_1.vue') //代码切割
const navModule_2 = () => import('@/pages/modules/navModule_2.vue') //代码切割

export default {
	components: {
		navModule_1,
		navModule_2,
		FootModuleLoader,
	},
	data() {
		return {
			firstAdVisible: false,
			secondAdVisible: false,
		}
	},
	computed: {
		adjustedItems() {
			return this.getAdjustedItems(this.themeConfig, this.list, 'adsPos', 'leadType', 'collectionType', 2)
		},
	},
	methods: {
		invalidClass(type) {
			if (type === 'lead' && !this.themeConfig.leadType) {
				return 'params-invalid'
			}
			if (type === 'collection' && !this.themeConfig.collectionType) {
				return 'params-invalid'
			}
			return ''
		},
		handleWindowLoad() {
			console.log('Window loaded')
		},
	},
}
