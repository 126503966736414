<template>
  <section ref="homme" class="home theme-with-nav"
    :style="{ background: themeConfig.backgroundColor }"
    :class="`home detect_deviece_${deviceType} state_${deviceType} page_is_${themeConfig.isArabia==1?'ar':'en'}`">
    <div v-if="isSanproject" class="shadow" />
    <component :is="`navModule_${themeConfig.navType}`" :host="host" :themeConfig="themeConfig"
      :domainConfig="domainConfig" />

    <div v-show="showDebug" class="ad-msg">
      <p>themeConfig --> {{ themeConfig }} </p>
      <hr>
      <p>domainConfig --> {{ domainConfig }} </p>
      <hr>
      <p>host --> {{ host }}</p>
    </div>

    <van-list v-model="loading" :finished="finished" :finished-text="bomTitle" :offset="20"
      class="main-feed" loading-text="Loading..." :immediate-check="false" @load="onLoad">
      <div class="newlist_row">
        <template v-for="(item, index) in adjustedItems">
          <component :is="item.componentType" v-if="item.adtype === 'component'"
            :key="`key_${index}`" :item="item.data"
            :class="[`item-theme-${item.data.type === 1 ? 'lead' : 'collection'}`, invalidClass(item.data.type === 1 ? 'lead' : 'collection')]"
            @customClick="goLanding(item.data.id)" />
        </template>
      </div>
    </van-list>

    <FootModuleLoader v-show="adjustedItems" />
  </section>
</template>

<script>
import commonMethods from '@/mixin/commonMethods.js';
import adjustedItemsMixin from '@/mixin/adjustedItemsMixin.js';

export default {
  name: 'HomeMain',
  mixins: [commonMethods, adjustedItemsMixin],
  data () {
    return {
      adStatusObserver: null,
      adDomObserver: null,
      adIntersectionObserver: null,
      iframeVisibilityTracker: null,
      isSanproject: false,
      adStatus: {} // 用于存储广告的可见状态
    }
  },
  created () {
    let projectChannel = this.$route.params.channel;
    console.log(projectChannel);
    if (projectChannel == 'sanproject') {
      this.isSanproject = true
    }
  },
  beforeDestroy () {
    console.log('-------------------beforeDestroy');
  },
  async activated () {
    console.log('Component activated-----------');
  },
  deactivated () {
    console.log('Component deactivated-----------');
  },
  async mounted () {
    console.log('-------------------mounted')
    this.initData()
  },
  methods: {
    getAdConfig (position) {
      // 假设 `position` 与 `adsense` 数组的索引对应
      return this.adsense[`list_ad_${position}`] || {};
    },
    async initData () {
      console.log('initData');

      try {
        await this.getList();
        console.log('data loaded');
        console.log('loadList func running');
        this.initPage();
      } catch (error) {
        console.error('An error occurred in getList:', error);
        // 可以在这里添加错误处理逻辑
      }
    },
    initPage () {
      this.$eventrackFb('show_page_news', 'expose', {
        pve_cur: '/h5page/home/',
      });
      window.onload = this.handleWindowLoad;
    },
    transform (str, baseRem) {
      let reg = /((-?\.?\d+)(\.\d+)?)rem/g;

      let newStr = str.replace(reg, function (match, pos) {
        let val = (parseFloat(pos) * baseRem).toFixed(2);
        return val;
      });
      return newStr;
    },

    goLanding (id) {
      console.log(id);
      this.$eventrackFb('news_goto_detail', 'click', {
        id
      });
      // this.$router.push({ name: 'landing', query: { id, thme: this.themeConfig.landType } });

      // 获取当前的 channel 参数
      const channel = this.$route.params.channel;

      // 判断 channel 是否为空，决定跳转路径
      const path = channel ? `/${channel}/landing` : `/landing`;

      // 执行路由跳转
      this.$router.push({ path, query: { id, theme: this.themeConfig.landType } });
    },

  },
}
</script>
